import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import {
  Trans,
  useTranslation,
} from 'gatsby-plugin-react-i18next'

import { Field, Form, Formik } from 'formik'
import {
  encode,
  Error,
  FieldWithErrorMessageAndLabel,
  LargeFieldWithErrorMessageAndLabel,
} from '../components/shared'

const initialFormValues = {
  attending: undefined,
  email: '',
  whoIsComing: '',
  dietaryRequirements: '',
  phone: '',
}

const RsvpResponse = ({ pageContext }) => {
  const invite = pageContext.invite
  const { t } = useTranslation()
  const validator = values => {
    const errors = {}
    if (
      values.attending === '' ||
      values.attending === null ||
      values.attending === undefined
    ) {
      errors.attending = t('Please select whether you are attending or not')
    }
    if (values.attending === 'false') return {}

    if (!values.whoIsComing) {
      errors.whoIsComing = t('Please enter who is coming')
    }

    if (
      values.whoIsComing.split(/\r\n|\r|\n/).length > invite.numberOfAttendees
    ) {
      errors.whoIsComing = t('attendeesValidation', {
        count: invite.numberOfAttendees,
      })
    }

    if (!values.email) {
      errors.email = t('Please enter an email address')
    }

    return errors
  }

  return (
    <Layout>
      <Helmet>
        <title>{invite.inviteName}</title>
      </Helmet>
      <div id="main">
        <h1>{invite.inviteName}</h1>
        <Formik
          initialValues={initialFormValues}
          // validationSchema = {validationSchema}
          validate={validator}
          onSubmit={values => {
            fetch('/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode({
                'form-name': 'rsvp',
                inviteName: invite.inviteName,
                invitePassword: invite.password,
                ...values,
              }),
            })
              .then(() => {
                console.log(values)
                if (typeof window !== 'undefined') {
                  if (values.attending === "true") {
                    window.location = '/rsvp-success-attending'
                  } else if (values.attending === "false") {
                    window.location = '/rsvp-success-not-attending'
                  }
                }
              })
              .catch(error =>
                alert(
                  t(
                    'Oops, someting went wrong. Please try again, or contact us if this keeps happening!'
                  )
                )
              )
          }}
        >
          {props => (
            <Form name="rsvp" data-netlify={true}>
              <div style={{display: "none"}}>
                <label htmlFor="inviteName">Invite Name:</label>
                <input id="inviteName" name="inviteName" type="text" />
                <label htmlFor="invitePassword">Invite Password:</label>
                <input id="invitePassword" name="invitePassword" type="text" />
              </div>
              <label htmlFor="attending">
                <Trans>Are you able to attend?</Trans>
              </label>
              <Field id="attending" name="attending" as="select">
                <option defaultValue />
                <option value="true">{t('Yes')}</option>
                <option value="false">{t('No')}</option>
              </Field>

              <Error name="attending" />
              <div
                style={{
                  display: props.values.attending === 'true' ? '' : 'none',
                }}
              >
                <p>
                  <Trans>We're so happy you can make it!</Trans>
                </p>
                <LargeFieldWithErrorMessageAndLabel
                  name={'whoIsComing'}
                  type={'text'}
                  rows={invite.numberOfAttendees}
                  message={t(
                    '*Please enter the full names of everyone who will be attending, one per line'
                  )}
                />
                <FieldWithErrorMessageAndLabel
                  name={'dietaryRequirements'}
                  type={'text'}
                  message={t(
                    'If you have any dietary requirements, enter them here'
                  )}
                />
                <FieldWithErrorMessageAndLabel
                  name={'email'}
                  type={'email'}
                  autocomplete={'email'}
                  message={t(
                    '*Please put a contact email address so we can get in touch with you about the wedding'
                  )}
                />
                <FieldWithErrorMessageAndLabel
                  name={'phone'}
                  type={'tel'}
                  autocomplete={'tel'}
                  message={t(
                    'Please put a contact phone number in case we need to reach you'
                  )}
                />
                <Error name="accomodation" />
                <LargeFieldWithErrorMessageAndLabel
                  name={'songsToPlay'}
                  type={'text'}
                  message={t(
                    'If there are any songs you would like us to play, please put them here!'
                  )}
                />
                <LargeFieldWithErrorMessageAndLabel
                  name={'anythingElse'}
                  type={'text'}
                  message={t('Anything else you want to add?')}
                />
              </div>
              {props.values.attending === 'false' && (
                <div>
                  <p>
                    <Trans>Sorry you can't make it, you will be missed!</Trans>
                  </p>
                </div>
              )}
              <br />
              <button type="submit">
                <Trans>Submit</Trans>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  )
}

export default RsvpResponse
